<template>
  <div class="checkprops-wraper pa-16">
    <div class="fw-500 text-center font-18 mb-24">{{ title }}</div>
    <div class="check-content d-flex flex-wrap justify-space-between">
      <div
        @click="handleCheckColor(item)"
        v-for="(item, index) in columns"
        :key="index"
        :style="{
          width: tagWidth,
          background: !item.mixinColor
            ? item.color
            : `linear-gradient(to right, ${item.color[0]} 50%, ${item.color[1]} 51%)`
        }"
        :class="{ colorWhite: item.color }"
        class="check-tag fw-500 font-16 lh-36 mb-16"
      >
        {{ item.value }}
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, nextTick } from '@vue/composition-api';
export default defineComponent({
  name: 'CheckPorps',
  model: {
    props: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: String,
      default: () => []
    },
    tagWidth: {
      type: String,
      default: '0.72rem'
    },
    title: {
      type: String,
      default: ''
    },
    columns: {
      type: Array,
      default: () => []
    }
  },
  setup(_, ctx) {
    const handleCheckColor = item => {
      ctx.emit('confirm', item);
      nextTick(() => {
        // 确保在Store更新完成之后才进行模态框的影藏
        ctx.emit('cancel');
      });
    };

    return {
      handleCheckColor
    };
  }
});
</script>
<style lang="less">
.check-tag {
  text-align: center;
  width: 0.72rem;
  height: 0.36rem;
  border: 1px solid rgba(188, 188, 188, 0.5);
  border-radius: 5px;
  &.colorWhite {
    color: white;
  }
}
</style>
